/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");
// Jquery
require("../../public/new/assets/libs/jquery/dist/jquery");
// Bootstrap tether Core JavaScript
require("../../public/new/assets/libs/popper.js/dist/umd/popper");
require("../../public/new/assets/libs/bootstrap/dist/js/bootstrap");
// apps
require("../../public/new/dist/js/app");
require("../../public/new/dist/js/app.init");
require("../../public/new/dist/js/app-style-switcher");
// slimscrollbar scrollbar JavaScript
require("../../public/new/assets/libs/perfect-scrollbar/dist/js/perfect-scrollbar.jquery");
require("../../public/new/assets/libs/jquery-sparkline/jquery.sparkline");
// Wave Effect
require("../../public/new/dist/js/waves");
// Menu sidebar
require("../../public/new/dist/js/sidebarmenu");
// Custom JavaScript
window.feather = require("../../public/new/dist/js/feather.min");
require("../../public/new/dist/js/custom");

require("../../public/admin/vendor/jquery-validation/jquery.validate");
require("../../public/admin/vendor/jquery-validation/additional-methods");
require("../../public/admin/vendor/bootstrap-notify/bootstrap-notify");
require("../../public/admin/vendor/bootstrap-datepicker/bootstrap-datepicker.min");
require("../../public/new/assets/libs/datatables/media/js/jquery.dataTables");
require("../../public/new/dist/js/pages/datatable/custom-datatable");
require("../../public/new/dist/js/pages/datatable/datatable-advanced.init");

require("../../public/admin/js/custom");

window.Vue = require("vue");

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component(
    "example-component",
    require("./components/ExampleComponent.vue").default
);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: "#app"
});

$.ajaxSetup({
    headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
    }
});
