$.ajaxSetup({
    headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
    }
});

$(document).ready(function() {
    $('[data-toggle="tooltip"]').tooltip();
    $(".preloader").fadeOut();
    $(".dataTable").DataTable();

    $("#btn-submit-paassword-email").click(function(e) {
        e.preventDefault();
        if ($("#passwordEmailForm").valid()) {
            $("#btn-submit-paassword-email").prop("disabled", true);
            $("#passwordEmailForm").submit();
        }
    });

    $("#passwordEmailForm").validate({
        rules: {
            username: {
                required: true
            },
            email: {
                required: true,
                email: true
            }
        },
        messages: {
            username: {
                required: "Please enter a username"
            },
            email: {
                required: "Please enter a email address",
                email: "Please enter a vaild email address"
            }
        },
        errorElement: "span",
        errorPlacement: function(error, element) {
            error.addClass("invalid-feedback");
            element.closest(".form-group").append(error);
        },
        highlight: function(element, errorClass, validClass) {
            $(element).addClass("is-invalid");
        },
        unhighlight: function(element, errorClass, validClass) {
            $(element).removeClass("is-invalid");
        }
    });

    $("#btn-submit-password-confirm").click(function(e) {
        e.preventDefault();
        if ($("#passwordConfirmForm").valid()) {
            $("#btn-submit-password-confirm").prop("disabled", true);
            $("#passwordConfirmForm").submit();
        }
    });

    $("#passwordConfirmForm").validate({
        rules: {
            password: {
                required: true,
                minlength: 8
            }
        },
        messages: {
            password: {
                required: "Please provide a password",
                minlength: "Your password must be at least 8 characters long"
            }
        },
        errorElement: "span",
        errorPlacement: function(error, element) {
            error.addClass("invalid-feedback");
            element.closest(".form-group").append(error);
        },
        highlight: function(element, errorClass, validClass) {
            $(element).addClass("is-invalid");
        },
        unhighlight: function(element, errorClass, validClass) {
            $(element).removeClass("is-invalid");
        }
    });

    $("#btn-submit-password-update").click(function(e) {
        e.preventDefault();
        if ($("#passwordUpdateForm").valid()) {
            $("#btn-submit-password-update").prop("disabled", true);
            $("#passwordUpdateForm").submit();
        }
    });

    $("#passwordUpdateForm").validate({
        rules: {
            email: {
                required: true,
                email: true
            },
            password: {
                required: true,
                minlength: 8
            },
            password_confirmation: {
                equalTo: "#password"
            }
        },
        messages: {
            email: {
                required: "Please enter a email address",
                email: "Please enter a vaild email address"
            },
            password: {
                required: "Please provide a password",
                minlength: "Your password must be at least 8 characters long"
            },
            password_confirmation: {
                equalTo: "The confirm password does not match"
            }
        },
        errorElement: "span",
        errorPlacement: function(error, element) {
            error.addClass("invalid-feedback");
            element.closest(".form-group").append(error);
        },
        highlight: function(element, errorClass, validClass) {
            $(element).addClass("is-invalid");
        },
        unhighlight: function(element, errorClass, validClass) {
            $(element).removeClass("is-invalid");
        }
    });

    $("#btn-submit-login").click(function(e) {
        e.preventDefault();
        if ($("#loginForm").valid()) {
            $("#btn-submit-login").prop("disabled", true);
            $("#loginForm").submit();
        }
    });

    $("#loginForm").validate({
        rules: {
            username: {
                required: true
            },
            password: {
                required: true
            }
        },
        messages: {
            username: {
                required: "Please enter a username"
            },
            password: {
                required: "Please provide a password"
            }
        },
        errorElement: "span",
        errorPlacement: function(error, element) {
            error.addClass("invalid-feedback");
            element.closest(".form-group").append(error);
        },
        highlight: function(element, errorClass, validClass) {
            $(element).addClass("is-invalid");
        },
        unhighlight: function(element, errorClass, validClass) {
            $(element).removeClass("is-invalid");
        }
    });

    $("#btn-submit-register").prop("disabled", true);
    $("#agreeTerms").change(function() {
        $("#btn-submit-register").prop("disabled", !this.checked);
    });
    $("#btn-modal-agreed").click(function() {
        $("#termsModal").modal("hide");
        $("#agreeTerms").prop("checked", true);
        $("#btn-submit-register").prop("disabled", false);
    });
    $("#btn-modal-close").click(function() {
        $("#termsModal").modal("hide");
        $("#agreeTerms").prop("checked", false);
        $("#btn-submit-register").prop("disabled", true);
    });

    $("#btn-submit-register").click(function(e) {
        e.preventDefault();
        if ($("#registerForm").valid()) {
            $("#btn-submit-register").prop("disabled", true);
            $("#registerForm").submit();
        }
    });

    jQuery.validator.addMethod(
        "phone",
        function(value, element) {
            return value
                ? /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(value)
                : true;
        },
        "Invalid contact no format"
    );

    jQuery.validator.addMethod(
        "positiveNumber",
        function(value, element) {
            return Number(value).toFixed(0) != 0
                ? /^[+]?\d+([.]\d+)?$/.test(value)
                : false;
        },
        "Only postive numbers are allowed"
    );

    jQuery.validator.addMethod(
        "noSpace",
        function(value, element) {
            return value.indexOf(" ") < 0 && value != "";
        },
        "Spaces are not allowed"
    );

    $("#registerForm").validate({
        rules: {
            clinic_name: {
                required: true,
                remote: {
                    url: "/validate-clinic-name",
                    type: "get"
                }
            },
            contact_no: {
                phone: true
            },
            first_name: {
                required: true
            },
            last_name: {
                required: true
            },
            email: {
                required: true,
                email: true
            },
            username: {
                required: true,
                noSpace: true,
                remote: {
                    url: "/validate-username",
                    type: "get"
                }
            },
            password: {
                required: true,
                minlength: 8
            },
            password_confirmation: {
                equalTo: "#password"
            }
        },
        messages: {
            clinic_name: {
                required: "Please enter a clinic name",
                remote: "Clinic name already exist"
            },
            first_name: {
                required: "Please enter a first name"
            },
            last_name: {
                required: "Please enter a last name"
            },
            email: {
                required: "Please enter a email address",
                email: "Please enter a vaild email address"
            },
            username: {
                required: "Please enter a username",
                remote: "Username already exist"
            },
            password: {
                required: "Please provide a password",
                minlength: "Your password must be at least 8 characters long"
            },
            password_confirmation: {
                equalTo: "The confirm password does not match"
            }
        },
        errorElement: "span",
        errorPlacement: function(error, element) {
            error.addClass("invalid-feedback");
            element.closest(".form-group-col").append(error);
        },
        highlight: function(element, errorClass, validClass) {
            $(element).addClass("is-invalid");
        },
        unhighlight: function(element, errorClass, validClass) {
            $(element).removeClass("is-invalid");
        }
    });

    $("#profileForm").ready(function() {
        if ($("#clinic_name").length && $("#clinic_name").val() != "") {
            $("#clinic_name").valid();
        }
        if ($("#username").length && $("#username").val() != "") {
            $("#username").valid();
        }
    });

    $("#user_image").change(function() {
        let reader = new FileReader();
        reader.onload = e => {
            $("#user_image_preview_container").attr("src", e.target.result);
        };
        reader.readAsDataURL(this.files[0]);
    });

    $("#btn-submit-profile").click(function(e) {
        e.preventDefault();
        if ($("#profileForm").valid()) {
            $("#btn-submit-profile").prop("disabled", true);
            $("#profileForm").submit();
        }
    });

    $("#profileForm").validate({
        rules: {
            user_image: {
                extension: "jpg|jpeg|png|jpg|gif|svg"
            },
            clinic_name: {
                required: true,
                remote: {
                    url: "/validate-clinic-name",
                    type: "get",
                    data: {
                        old_value: function() {
                            return $("#clinic_name_value").val();
                        }
                    }
                }
            },
            contact_no: {
                phone: true
            },
            first_name: {
                required: true
            },
            last_name: {
                required: true
            },
            email: {
                required: true,
                email: true
            },
            username: {
                required: true,
                noSpace: true,
                remote: {
                    url: "/validate-username",
                    type: "get",
                    data: {
                        old_value: function() {
                            return $("#username_value").val();
                        }
                    }
                }
            },
            password: {
                minlength: 8
            },
            password_confirmation: {
                equalTo: "#password"
            }
        },
        messages: {
            user_image: {
                extension: "Only jpg,jpeg,png,jpg,gif,svg files are allowed"
            },
            clinic_name: {
                required: "Please enter a clinic name",
                remote: "Clinic name already exist"
            },
            first_name: {
                required: "Please enter a first name"
            },
            last_name: {
                required: "Please enter a last name"
            },
            email: {
                required: "Please enter a email address",
                email: "Please enter a vaild email address"
            },
            username: {
                required: "Please enter a username",
                remote: "Username already exist"
            },
            password: {
                minlength: "Your password must be at least 8 characters long"
            },
            password_confirmation: {
                equalTo: "The confirm password does not match"
            }
        },
        errorElement: "span",
        errorPlacement: function(error, element) {
            error.addClass("invalid-feedback");
            element.closest(".form-group-col").append(error);
        },
        highlight: function(element, errorClass, validClass) {
            $(element).addClass("is-invalid");
        },
        unhighlight: function(element, errorClass, validClass) {
            $(element).removeClass("is-invalid");
        }
    });

    $("#profileForm").submit(function(e) {
        e.preventDefault();

        var formData = new FormData(this);

        if ($("#profileForm").valid()) {
            $("#btn-submit-profile").prop("disabled", true);
            $.ajax({
                type: "POST",
                url: "/update-profile",
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                success: data => {
                    $("#btn-submit-profile").prop("disabled", false);
                    $.notify(
                        {
                            // options
                            message: data.message
                        },
                        {
                            // settings
                            type: data.response ? "success" : "danger",
                            placement: {
                                from: "bottom",
                                align: "right"
                            }
                        }
                    );
                    if (data.response) {
                        setTimeout(function() {
                            location.reload(true);
                        }, 1000);
                    }
                },
                error: function(data) {
                    $("#btn-submit-profile").prop("disabled", false);
                    console.log(data);
                }
            });
        }
    });

    $("#clinicDataTable").DataTable({
        processing: true,
        serverSide: true,
        ajax: {
            type: "GET",
            url: "/clinic"
        },
        columns: [
            {
                data: "clinic_name",
                name: "clinic_name",
                className: "table-row-vertical-middle"
            },
            {
                data: "registered_date_time",
                name: "registered_date_time",
                className: "table-row-text-center-vertical-middle"
            },
            {
                data: "contact_no",
                name: "contact_no",
                className: "table-row-vertical-middle",
                orderable: false
            },
            {
                data: "email",
                name: "email",
                render: function(data, type, row, meta) {
                    return '<a href="mailto:' + data + '">' + data + "</a>";
                },
                className: "table-row-vertical-middle",
                orderable: false
            },
            {
                data: "no_of_doctors",
                name: "no_of_doctors",
                className: "table-row-text-center-vertical-middle-nowrap"
            },
            {
                data: "status",
                name: "status",
                render: function(data, type, row, meta) {
                    var status = "";
                    var status_class = "";
                    switch (data) {
                        case "A":
                            status = "Active";
                            status_class = "success";
                            break;
                        case "I":
                            status = "Inactive";
                            status_class = "secondary";
                            break;
                    }
                    return (
                        '<span class="clinic-badge badge bg-' +
                        status_class +
                        '">' +
                        status +
                        "</span>"
                    );
                },
                className: "table-row-text-center-vertical-middle-nowrap"
            },
            {
                data: "action",
                name: "action",
                className: "table-row-text-center-vertical-middle-nowrap",
                orderable: false
            }
        ],
        order: [[1, "desc"]],
        dom: "Bfrtip",
        buttons: [
            {
                extend: "copyHtml5",
                exportOptions: {
                    columns: [0, 1, 2, 3, 4]
                },
                title: "MediSeek - Clinics Report"
            },
            {
                extend: "excelHtml5",
                exportOptions: {
                    columns: [0, 1, 2, 3, 4]
                },
                title: "MediSeek - Clinics Report"
            },
            {
                extend: "csvHtml5",
                exportOptions: {
                    columns: [0, 1, 2, 3, 4]
                },
                title: "MediSeek - Clinics Report"
            },
            {
                extend: "print",
                exportOptions: {
                    columns: [0, 1, 2, 3, 4]
                },
                title: "MediSeek - Clinics Report"
            }
        ],
        drawCallback: function(settings) {
            $("button[name='view-clinic']").click(function() {
                var id = $(this).attr("data-id");
                $.ajax({
                    type: "GET",
                    url: "/clinic/" + id,
                    success: data => {
                        $("#modal-body-view-clinic").html(data.view);
                        $("#viewModal").modal("show");
                    },
                    error: function(data) {
                        console.log(data);
                    }
                });
            });

            $("button[name='deactive-clinic']").click(function() {
                var id = $(this).attr("data-id");
                var name = $(this).attr("data-name");
                var status = $(this).attr("data-status");

                $("#confirmModalLabel").html("Deactive Clinic");
                $("#modal-body-clinic-confirm").html(
                    "Are you sure you want to deactive " + name + "?"
                );
                $("#btn-modal-clinic-confirm").removeClass("btn-success");
                $("#btn-modal-clinic-confirm").addClass("btn-danger");

                $("#btn-modal-clinic-confirm").attr("data-id", id);
                $("#btn-modal-clinic-confirm").attr("data-status", status);
            });

            $("button[name='active-clinic']").click(function() {
                var id = $(this).attr("data-id");
                var name = $(this).attr("data-name");
                var status = $(this).attr("data-status");

                $("#confirmModalLabel").html("Active Clinic");
                $("#modal-body-clinic-confirm").html(
                    "Are you sure you want to active " + name + "?"
                );
                $("#btn-modal-clinic-confirm").removeClass("btn-danger");
                $("#btn-modal-clinic-confirm").addClass("btn-success");

                $("#btn-modal-clinic-confirm").attr("data-id", id);
                $("#btn-modal-clinic-confirm").attr("data-status", status);
            });
        }
    });

    $("#btn-modal-clinic-confirm").click(function() {
        var id = $(this).attr("data-id");
        var status = $(this).attr("data-status");

        $("#confirmModal").modal("hide");

        $("#btn-modal-clinic-confirm").prop("disabled", true);
        $.ajax({
            type: "POST",
            url: "/update-user-status",
            data: {
                id: id,
                status: status
            },
            cache: false,
            success: data => {
                $("#btn-modal-clinic-confirm").prop("disabled", false);
                $.notify(
                    {
                        // options
                        message: data.message
                    },
                    {
                        // settings
                        type: data.response ? "success" : "danger",
                        placement: {
                            from: "bottom",
                            align: "right"
                        }
                    }
                );
                $("#clinicDataTable")
                    .DataTable()
                    .draw();
            },
            error: function(data) {
                $("#btn-modal-clinic-confirm").prop("disabled", false);
                console.log(data);
            }
        });
    });

    $("#updateClinicForm").ready(function() {
        if ($("#clinic_name").length && $("#clinic_name").val() != "") {
            $("#clinic_name").valid();
        }
        if ($("#username").length && $("#username").val() != "") {
            $("#username").valid();
        }
    });

    $("#status").change(function() {
        $("#status").valid();
    });

    $("#btn-submit-update-clinic").click(function(e) {
        e.preventDefault();
        if ($("#updateClinicForm").valid()) {
            $("#btn-submit-update-clinic").prop("disabled", true);
            $("#updateClinicForm").submit();
        }
    });

    $("#updateClinicForm").validate({
        rules: {
            clinic_name: {
                required: true,
                remote: {
                    url: "/validate-clinic-name",
                    type: "get",
                    data: {
                        old_value: function() {
                            return $("#clinic_name_value").val();
                        }
                    }
                }
            },
            contact_no: {
                phone: true
            },
            first_name: {
                required: true
            },
            last_name: {
                required: true
            },
            email: {
                required: true,
                email: true
            },
            username: {
                required: true,
                noSpace: true,
                remote: {
                    url: "/validate-username",
                    type: "get",
                    data: {
                        old_value: function() {
                            return $("#username_value").val();
                        }
                    }
                }
            },
            status: {
                required: true
            }
        },
        messages: {
            clinic_name: {
                required: "Please enter a clinic name",
                remote: "Clinic name already exist"
            },
            first_name: {
                required: "Please enter a first name"
            },
            last_name: {
                required: "Please enter a last name"
            },
            email: {
                required: "Please enter a email address",
                email: "Please enter a vaild email address"
            },
            username: {
                required: "Please enter a username",
                remote: "Username already exist"
            },
            status: {
                required: "Please select a status"
            }
        },
        errorElement: "span",
        errorPlacement: function(error, element) {
            error.addClass("invalid-feedback");
            element.closest(".form-group-col").append(error);
        },
        highlight: function(element, errorClass, validClass) {
            $(element).addClass("is-invalid");
        },
        unhighlight: function(element, errorClass, validClass) {
            $(element).removeClass("is-invalid");
        }
    });

    $("#updateClinicForm").submit(function(e) {
        e.preventDefault();

        var formData = new FormData(this);

        if ($("#updateClinicForm").valid()) {
            $("#btn-submit-update-clinic").prop("disabled", true);
            $.ajax({
                type: "POST",
                url: "/clinic",
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                success: data => {
                    $("#btn-submit-update-clinic").prop("disabled", false);
                    $.notify(
                        {
                            // options
                            message: data.message
                        },
                        {
                            // settings
                            type: data.response ? "success" : "danger",
                            placement: {
                                from: "bottom",
                                align: "right"
                            }
                        }
                    );
                    setTimeout(function() {
                        window.location.href = "/clinic";
                    }, 1000);
                },
                error: function(data) {
                    $("#btn-submit-update-clinic").prop("disabled", false);
                    console.log(data);
                }
            });
        }
    });

    $("#btn-submit-update-setting").click(function(e) {
        e.preventDefault();
        if ($("#settingForm").valid()) {
            $("#btn-submit-update-setting").prop("disabled", true);
            $("#settingForm").submit();
        }
    });

    $("#settingForm").validate({
        rules: {
            stripe_publishable_key: {
                required: true
            },
            stripe_secret_key: {
                required: true
            },
            default_amount: {
                required: true,
                positiveNumber: true
            },
            currency: {
                required: true
            },
            timezone: {
                required: true
            }
        },
        messages: {
            stripe_publishable_key: {
                required: "Please enter a Stripe Publishable Key"
            },
            stripe_secret_key: {
                required: "Please enter a Stripe Secret Key"
            },
            default_amount: {
                required: "Please enter a Default Amount"
            },
            currency: {
                required: "Please select a Currency"
            },
            timezone: {
                required: "Please select a Timezone"
            }
        },
        errorElement: "span",
        errorPlacement: function(error, element) {
            error.addClass("invalid-feedback");
            element.closest(".form-group-col").append(error);
        },
        highlight: function(element, errorClass, validClass) {
            $(element).addClass("is-invalid");
        },
        unhighlight: function(element, errorClass, validClass) {
            $(element).removeClass("is-invalid");
        }
    });

    $("#settingForm").submit(function(e) {
        e.preventDefault();

        var formData = new FormData(this);

        if ($("#settingForm").valid()) {
            $("#btn-submit-update-setting").prop("disabled", true);
            $.ajax({
                type: "POST",
                url: "/update-setting",
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                success: data => {
                    $("#btn-submit-update-setting").prop("disabled", false);
                    $.notify(
                        {
                            // options
                            message: data.message
                        },
                        {
                            // settings
                            type: data.response ? "success" : "danger",
                            placement: {
                                from: "bottom",
                                align: "right"
                            }
                        }
                    );
                },
                error: function(data) {
                    $("#btn-submit-update-setting").prop("disabled", false);
                    console.log(data);
                }
            });
        }
    });

    $("#doctorForm").ready(function() {
        if ($("#username").length && $("#username").val() != "") {
            $("#username").valid();
        }
    });

    $("#btn-submit-doctor").click(function(e) {
        e.preventDefault();
        if ($("#doctorForm").valid()) {
            $("#btn-submit-doctor").prop("disabled", true);
            $("#doctorForm").submit();
        }
    });

    $("#doctorForm").validate({
        rules: {
            first_name: {
                required: true,
                maxlength: 25
            },
            last_name: {
                required: true,
                maxlength: 25
            },
            email: {
                required: true,
                email: true
            },
            username: {
                required: true,
                noSpace: true,
                remote: {
                    url: "/validate-username",
                    type: "get",
                    data: {
                        old_value: function() {
                            return $("#username_value").val();
                        }
                    }
                }
            },
            status: {
                required: true
            }
        },
        messages: {
            first_name: {
                required: "Please enter a first name"
            },
            last_name: {
                required: "Please enter a last name"
            },
            email: {
                required: "Please enter a email address",
                email: "Please enter a vaild email address"
            },
            username: {
                required: "Please enter a username",
                remote: "Username already exist"
            },
            status: {
                required: "Please select a status"
            }
        },
        errorElement: "span",
        errorPlacement: function(error, element) {
            error.addClass("invalid-feedback");
            element.closest(".form-group-col").append(error);
        },
        highlight: function(element, errorClass, validClass) {
            $(element).addClass("is-invalid");
        },
        unhighlight: function(element, errorClass, validClass) {
            $(element).removeClass("is-invalid");
        }
    });

    $("#doctorForm").submit(function(e) {
        e.preventDefault();

        var id = $("#user_id").val();
        var formData = new FormData(this);

        if ($("#doctorForm").valid()) {
            $("#btn-submit-doctor").prop("disabled", true);
            $.ajax({
                type: "POST",
                url: "/doctors",
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                success: data => {
                    $("#btn-submit-doctor").prop("disabled", false);
                    $.notify(
                        {
                            // options
                            message: data.message
                        },
                        {
                            // settings
                            type: data.response ? "success" : "danger",
                            placement: {
                                from: "bottom",
                                align: "right"
                            }
                        }
                    );
                    if (id == "") {
                        this.reset();
                    } else {
                        setTimeout(function() {
                            window.location.href = "/doctors";
                        }, 1000);
                    }
                },
                error: function(data) {
                    $("#btn-submit-doctor").prop("disabled", false);
                    console.log(data);
                }
            });
        }
    });

    $("#doctorDataTable").DataTable({
        processing: true,
        serverSide: true,
        ajax: {
            type: "GET",
            url: "/doctors"
        },
        columns: [
            {
                data: "first_name",
                name: "first_name",
                render: function(data, type, row, meta) {
                    return row["first_name"] + " " + row["last_name"];
                },
                className: "table-row-vertical-middle"
            },
            {
                data: "email",
                name: "email",
                render: function(data, type, row, meta) {
                    return '<a href="mailto:' + data + '">' + data + "</a>";
                },
                className: "table-row-vertical-middle"
            },
            {
                data: "username",
                name: "username",
                className: "table-row-vertical-middle"
            },
            {
                data: "status",
                name: "status",
                render: function(data, type, row, meta) {
                    var status = "";
                    var status_class = "";
                    switch (data) {
                        case "A":
                            status = "Active";
                            status_class = "success";
                            break;
                        case "I":
                            status = "Inactive";
                            status_class = "secondary";
                            break;
                    }
                    return (
                        '<span class="clinic-badge badge bg-' +
                        status_class +
                        '">' +
                        status +
                        "</span>"
                    );
                },
                className: "table-row-text-center-vertical-middle-nowrap"
            },
            {
                data: "action",
                name: "action",
                className: "table-row-text-center-vertical-middle-nowrap",
                orderable: false
            }
        ],
        order: [[0, "desc"]],
        dom: "Bfrtip",
        buttons: [
            {
                extend: "copyHtml5",
                exportOptions: {
                    columns: [0, 1, 2, 3]
                },
                title: "MediSeek - Doctors Report"
            },
            {
                extend: "excelHtml5",
                exportOptions: {
                    columns: [0, 1, 2, 3]
                },
                title: "MediSeek - Doctors Report"
            },
            {
                extend: "csvHtml5",
                exportOptions: {
                    columns: [0, 1, 2, 3]
                },
                title: "MediSeek - Doctors Report"
            },
            {
                extend: "print",
                exportOptions: {
                    columns: [0, 1, 2, 3]
                },
                title: "MediSeek - Doctors Report"
            }
        ],
        drawCallback: function(settings) {
            $("button[name='view-doctor']").click(function() {
                var id = $(this).attr("data-id");
                $.ajax({
                    type: "GET",
                    url: "/doctors/" + id,
                    success: data => {
                        $("#modal-body-view-doctor").html(data.view);
                        $("#viewModal").modal("show");
                    },
                    error: function(data) {
                        console.log(data);
                    }
                });
            });

            $("button[name='resend-invitation']").click(function() {
                var id = $(this).attr("data-id");
                var name = $(this).attr("data-name");
                var status = $(this).attr("data-status");
                var action = $(this).attr("data-action");

                $("#confirmModalLabel").html("Resend Doctor Invitation");
                $("#modal-body-doctor-confirm").html(
                    "Are you sure you want to resend invitation of Dr. " + name + "?"
                );
                $("#btn-modal-doctor-confirm").removeClass("btn-success");
                $("#btn-modal-doctor-confirm").addClass("btn-danger");

                $("#btn-modal-doctor-confirm").attr("data-id", id);
                $("#btn-modal-doctor-confirm").attr("data-status", status);
                $("#btn-modal-doctor-confirm").attr("data-action", action);
            });

            $("button[name='delete-doctor']").click(function() {
                var id = $(this).attr("data-id");
                var name = $(this).attr("data-name");
                var status = $(this).attr("data-status");
                var action = $(this).attr("data-action");

                $("#confirmModalLabel").html("Delete Doctor");
                $("#modal-body-doctor-confirm").html(
                    "Are you sure you want to delete Dr. " + name + "?"
                );
                $("#btn-modal-doctor-confirm").removeClass("btn-success");
                $("#btn-modal-doctor-confirm").addClass("btn-danger");

                $("#btn-modal-doctor-confirm").attr("data-id", id);
                $("#btn-modal-doctor-confirm").attr("data-status", status);
                $("#btn-modal-doctor-confirm").attr("data-action", action);
            });

            $("button[name='deactive-doctor']").click(function() {
                var id = $(this).attr("data-id");
                var name = $(this).attr("data-name");
                var status = $(this).attr("data-status");
                var action = $(this).attr("data-action");

                $("#confirmModalLabel").html("Deactive Doctor");
                $("#modal-body-doctor-confirm").html(
                    "Are you sure you want to deactive Dr. " + name + "?"
                );
                $("#btn-modal-doctor-confirm").removeClass("btn-success");
                $("#btn-modal-doctor-confirm").addClass("btn-danger");

                $("#btn-modal-doctor-confirm").attr("data-id", id);
                $("#btn-modal-doctor-confirm").attr("data-status", status);
                $("#btn-modal-doctor-confirm").attr("data-action", action);
            });

            $("button[name='active-doctor']").click(function() {
                var id = $(this).attr("data-id");
                var name = $(this).attr("data-name");
                var status = $(this).attr("data-status");
                var action = $(this).attr("data-action");

                $("#confirmModalLabel").html("Active Doctor");
                $("#modal-body-doctor-confirm").html(
                    "Are you sure you want to active Dr. " + name + "?"
                );
                $("#btn-modal-doctor-confirm").removeClass("btn-danger");
                $("#btn-modal-doctor-confirm").addClass("btn-success");

                $("#btn-modal-doctor-confirm").attr("data-id", id);
                $("#btn-modal-doctor-confirm").attr("data-status", status);
                $("#btn-modal-doctor-confirm").attr("data-action", action);
            });
        }
    });

    $("#btn-modal-doctor-confirm").click(function() {
        var id = $(this).attr("data-id");
        var status = $(this).attr("data-status");
        var action = $(this).attr("data-action");

        $("#confirmModal").modal("hide");

        $("#btn-modal-doctor-confirm").prop("disabled", true);
        if (action == "resend") {
            $.ajax({
                type: "POST",
                url: "/doctor-resend-invitation",
                data: {
                    id: id
                },
                cache: false,
                success: data => {
                    $("#btn-modal-doctor-confirm").prop("disabled", false);
                    $.notify(
                        {
                            // options
                            message: data.message
                        },
                        {
                            // settings
                            type: data.response ? "success" : "danger",
                            placement: {
                                from: "bottom",
                                align: "right"
                            }
                        }
                    );
                    $("#doctorDataTable")
                        .DataTable()
                        .draw();
                },
                error: function(data) {
                    $("#btn-modal-doctor-confirm").prop("disabled", false);
                    console.log(data);
                }
            });
        } else if (action == "status") {
            $.ajax({
                type: "POST",
                url: "/update-user-status",
                data: {
                    id: id,
                    status: status
                },
                cache: false,
                success: data => {
                    $("#btn-modal-doctor-confirm").prop("disabled", false);
                    $.notify(
                        {
                            // options
                            message: data.message
                        },
                        {
                            // settings
                            type: data.response ? "success" : "danger",
                            placement: {
                                from: "bottom",
                                align: "right"
                            }
                        }
                    );
                    $("#doctorDataTable")
                        .DataTable()
                        .draw();
                },
                error: function(data) {
                    $("#btn-modal-doctor-confirm").prop("disabled", false);
                    console.log(data);
                }
            });
        } else if (action == "delete") {
            $.ajax({
                type: "DELETE",
                url: "/doctors/" + id,
                cache: false,
                contentType: false,
                processData: false,
                success: data => {
                    $("#btn-modal-doctor-confirm").prop("disabled", false);
                    $.notify(
                        {
                            // options
                            message: data.message
                        },
                        {
                            // settings
                            type: data.response ? "success" : "danger",
                            placement: {
                                from: "bottom",
                                align: "right"
                            }
                        }
                    );
                    setTimeout(function() {
                        window.location.href = "/doctors";
                    }, 1000);
                },
                error: function(data) {
                    $("#btn-modal-doctor-confirm").prop("disabled", false);
                    console.log(data);
                }
            });
        }
    });

    $("#btn-submit-message").click(function(e) {
        e.preventDefault();
        if ($("#shareEmailForm").valid()) {
            $("#btn-submit-message").prop("disabled", true);
            $("#shareEmailForm").submit();
        }
    });

    $("#shareEmailForm").validate({
        rules: {
            patient_email: {
                required: true,
                email: true
            },
            email_body: {
                required: true
            }
        },
        messages: {
            patient_email: {
                required: "Please enter patient email",
                email: "Please enter valid email address"
            },
            email_body: {
                required: "Please enter email body"
            }
        },
        errorElement: "span",
        errorPlacement: function(error, element) {
            error.addClass("invalid-feedback");
            element.closest(".form-group").append(error);
        },
        highlight: function(element, errorClass, validClass) {
            $(element).addClass("is-invalid");
        },
        unhighlight: function(element, errorClass, validClass) {
            $(element).removeClass("is-invalid");
        }
    });

    $("#shareEmailForm").submit(function(e) {
        e.preventDefault();

        if ($("#shareEmailForm").valid()) {
            $("#btn-submit-message").prop("disabled", true);

            $.ajax({
                type: "POST",
                url: "/url-share",
                data: {
                    patient_email: $("#patient_email").val(),
                    email_body: $("#email_body").val()
                },
                cache: false,
                success: data => {
                    $("#btn-submit-message").prop("disabled", false);
                    $("#systemEmail").modal("hide");
                    $("#emailShare").prop("selectedIndex", 0);
                    $.notify(
                        {
                            // options
                            message: data.message
                        },
                        {
                            // settings
                            type: data.response ? "success" : "danger",
                            placement: {
                                from: "bottom",
                                align: "right"
                            }
                        }
                    );
                },
                error: function(data) {
                    $("#btn-submit-message").prop("disabled", false);
                    console.log(data);
                }
            });
        }
    });

    $("#clipboardCopy").on("click", function() {
        var deafaultText = $("#clipboardCopy").html();
        $("#clipboardCopy").html("Copied");

        setTimeout(function() {
            $("#clipboardCopy").html(deafaultText);
        }, 2000);
    });

    var startDate = new Date();
    startDate.setDate(startDate.getDate() - 6);
    var endDate = new Date();
    $("#financial_date_range").daterangepicker({
            showDropdowns: true,
            startDate: startDate,
            endDate: endDate
        },
        function(start, end, label) {
            $("#financial_from").val(start.format("YYYY-MM-DD"));
            $("#financial_to").val(end.format("YYYY-MM-DD"));
            loadFinancialDataTable();
        }
    );

    $("#financial_clinic").change(function() {
        loadFinancialDataTable();
    });

    function loadFinancialDataTable() {
        if ($.fn.DataTable.isDataTable("#financialDataTable")) {
            $("#financialDataTable")
                .DataTable()
                .destroy();
        }
        $("#financialDataTable tbody").empty();

        $("#financialDataTable").DataTable({
            processing: true,
            serverSide: true,
            ajax: {
                type: "GET",
                url: "/financial-report",
                data: {
                    clinic_id: $("#financial_clinic").val(),
                    from: $("#financial_from").val(),
                    to: $("#financial_to").val()
                }
            },
            columns: [
                {
                    data: "first_name",
                    name: "first_name",
                    render: function(data, type, row, meta) {
                        return row["first_name"] + " " + row["last_name"];
                    },
                    className: "table-row-vertical-middle"
                },
                {
                    data: "email",
                    name: "email",
                    render: function(data, type, row, meta) {
                        return '<a href="mailto:' + data + '">' + data + "</a>";
                    },
                    className: "table-row-vertical-middle"
                },
                {
                    data: "stripe_id",
                    name: "payment_ref",
                    className: "table-row-vertical-middle"
                },
                {
                    data: "payment_amount",
                    name: "payment_amount",
                    className: "table-row-text-right-vertical-middle"
                },
                {
                    data: "patient_name",
                    name: "patient_name",
                    className: "table-row-vertical-middle"
                },
                {
                    data: "payment_date_time",
                    name: "payment_date_time",
                    className: "table-row-text-center-vertical-middle"
                },
                {
                    data: "payment_status",
                    name: "payment_status",
                    render: function(data, type, row, meta) {
                        var status = "";
                        var status_class = "";
                        switch (data) {
                            case "S":
                                status = "Success";
                                status_class = "success";
                                break;
                            case "F":
                                status = "Failed";
                                status_class = "secondary";
                                break;
                        }
                        return (
                            '<span class="clinic-badge badge bg-' +
                            status_class +
                            '">' +
                            status +
                            "</span>"
                        );
                    },
                    className: "table-row-text-center-vertical-middle-nowrap"
                }
            ],
            order: [[0, "desc"]],
            dom: "Bfrtip",
            buttons: [
                {
                    extend: "copyHtml5",
                    exportOptions: {
                        columns: ":visible"
                    },
                    title: "MediSeek - Financial Report"
                },
                {
                    extend: "excelHtml5",
                    exportOptions: {
                        columns: ":visible"
                    },
                    title: "MediSeek - Financial Report"
                },
                {
                    extend: "csvHtml5",
                    exportOptions: {
                        columns: ":visible"
                    },
                    title: "MediSeek - Financial Report"
                },
                {
                    extend: "print",
                    exportOptions: {
                        columns: ":visible"
                    },
                    title: "MediSeek - Financial Report"
                },
                "colvis"
            ]
        });

        $(
            ".buttons-copy, .buttons-csv, .buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis"
        ).addClass("btn btn-primary mr-1");
    }

    loadFinancialDataTable();

    $(
        ".buttons-copy, .buttons-csv, .buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis"
    ).addClass("btn btn-primary mr-1");
    $("div#doctorDataTable_filter").css("margin-top", "5px");

    var currentUrl = window.location.href;
    if(!currentUrl.includes("/appointment")){
        localStorage.setItem('appointment_from', '');
        localStorage.setItem('appointment_to', '');
    }

    var appointmentStartDate = new Date($("#appointment_from").val());
    var appointmentEndDate = new Date($("#appointment_to").val());
    var appointmentFrom = localStorage.getItem('appointment_from');
    var appointmentTo = localStorage.getItem('appointment_to');

    if(appointmentFrom != '' && appointmentTo != ''){
        appointmentStartDate = new Date(appointmentFrom);
        appointmentEndDate = new Date(appointmentTo);
        $("#appointment_from").val(appointmentFrom);
        $("#appointment_to").val(appointmentTo);
    }
    
    $("#appointment_time_range").daterangepicker({
            showDropdowns: true,
            startDate: appointmentStartDate,
            endDate: appointmentEndDate,
            locale: {
                format: 'DD/MM/YYYY'
            }
        },
        function(start, end, label) {
            $("#appointment_from").val(start.format("YYYY-MM-DD"));
            $("#appointment_to").val(end.format("YYYY-MM-DD"));
            localStorage.setItem('appointment_from', start.format("YYYY-MM-DD"));
            localStorage.setItem('appointment_to', end.format("YYYY-MM-DD"));
            loadAppointmentDataTable();
        }
    );

    function loadAppointmentDataTable() {
        if ($.fn.DataTable.isDataTable("#appointmentDataTable")) {
            $("#appointmentDataTable")
                .DataTable()
                .destroy();
        }
        $("#appointmentDataTable tbody").empty();

        $("#appointmentDataTable").DataTable({
            "language": {
                "emptyTable": "No appointments available"
            },
            processing: true,
            serverSide: true,
            ajax: {
                type: "GET",
                url: "/appointment",
                data: {
                    from: $("#appointment_from").val(),
                    to: $("#appointment_to").val(),
                    doctor: $('#appointment_doctor').val()
                }
            },
            columns: [
                {
                    data: "appointment_ref",
                    name: "appointment_ref",
                    className: "table-row-vertical-left"
                },
                {
                    data: "first_name",
                    name: "first_name",
                    render: function(data, type, row, meta) {
                        return row["first_name"] + " " + row["last_name"];
                    },
                    className: "table-row-vertical-middle",
                    visible: $("#user_type").val() == 'D' ? false : true
                },
                {
                    data: "patient_name",
                    name: "patient_name",
                    className: "table-row-vertical-middle"
                },
                {
                    data: "email",
                    name: "email",
                    render: function(data, type, row, meta) {
                        return '<a href="mailto:' + data + '">' + data + "</a>";
                    },
                    className: "table-row-vertical-middle"
                },
                {
                    data: "appointment_time",
                    name: "appointment_time",
                    className: "table-row-vertical-middle"
                },
                {
                    data: "status",
                    name: "status",
                    render: function(data, type, row, meta) {
                        var status = "";
                        var status_class = "";
                        switch (data) {
                            case "P":
                                status = "Pending";
                                status_class = "warning";
                                break;
                            case "E":
                                status = "Closed";
                                status_class = "success";
                                break;
                            case "C":
                                status = "Cancelled";
                                status_class = "danger";
                                break;
                        }
                        return (
                            '<span class="appointment-badge badge bg-' +
                            status_class +
                            '">' +
                            status +
                            "</span>"
                        );
                    },
                    className: "table-row-text-center-vertical-middle-nowrap"
                },
                {
                    data: "action",
                    name: "action",
                    className: "table-row-text-center-vertical-middle-nowrap",
                    orderable: false
                }
            ],
            order: [[0, "desc"]],
            dom: "Bfrtip",
            buttons: [
                {
                    extend: "copyHtml5",
                    exportOptions: {
                        columns: $("#user_type").val() == 'D' ? [0, 2, 3, 4] : [0, 1, 2, 3, 4]
                    },
                    title: "MediSeek - Appointments Report"
                },
                {
                    extend: "excelHtml5",
                    exportOptions: {
                        columns: $("#user_type").val() == 'D' ? [0, 2, 3, 4] : [0, 1, 2, 3, 4]
                    },
                    title: "MediSeek - Appointments Report"
                },
                {
                    extend: "csvHtml5",
                    exportOptions: {
                        columns: $("#user_type").val() == 'D' ? [0, 2, 3, 4] : [0, 1, 2, 3, 4]
                    },
                    title: "MediSeek - Appointments Report"
                },
                {
                    extend: "print",
                    exportOptions: {
                        columns: $("#user_type").val() == 'D' ? [0, 2, 3, 4] : [0, 1, 2, 3, 4]
                    },
                    title: "MediSeek - Appointments Report"
                }
            ],
            drawCallback: function(settings) {
                $("button[name='view-appointment']").click(function() {
                    var id = $(this).attr("data-id");
                    $.ajax({
                        type: "GET",
                        url: "/appointment/" + id,
                        success: data => {
                            $("#modal-body-view-appointment").html(data.view);
                            $("#viewModal").modal("show");
                        },
                        error: function(data) {
                            console.log(data);
                        }
                    });
                });
    
                $("button[name='cancel-appointment']").click(function() {
                    var id = $(this).attr("data-id");
                    var ref = $(this).attr("data-ref");
                    var action = $(this).attr("data-action");
    
                    $("#confirmModalLabel").html("Cancel Appointment");
                    $("#modal-body-appointment-cancel").html(
                        "Are you sure you want to cancel Appointment Ref: " + ref + "?"
                    );
                    $("#btn-modal-appointment-cancel").removeClass("btn-success");
                    $("#btn-modal-appointment-cancel").addClass("btn-danger");
    
                    $("#btn-modal-appointment-cancel").attr("data-id", id);
                    $("#btn-modal-appointment-cancel").attr("data-action", action);
                });
            }
        });

        $(
            ".buttons-copy, .buttons-csv, .buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis"
        ).addClass("btn btn-primary mr-1");
    }

    loadAppointmentDataTable();

    $('#appointment_doctor').change(function(e){
        loadAppointmentDataTable();
    });

    $("#btn-submit-appointment").click(function(e) {
        e.preventDefault();
        if ($("#appointmentForm").valid()) {
            $("#btn-submit-appointment").prop("disabled", true);
            $("#appointmentForm").submit();
        }
    });

    $("#appointmentForm").validate({
        rules: {
            doctor: {
                required: true
            },
            patient_name: {
                required: true,
                patientName: true
            },
            email: {
                email: true
            },
            appointment_time: {
                required: true
            }
        },
        messages: {
            doctor: {
                required: "Please select a doctor"
            },
            patient_name: {
                required: "Please select a patient"
            },
            email: {
                email: "Please enter a vaild email address"
            },
            appointment_time: {
                required: "Please select appointment time"
            }
        },
        errorElement: "span",
        errorPlacement: function(error, element) {
            error.addClass("invalid-feedback");
            element.closest(".form-group-col").append(error);
        },
        highlight: function(element, errorClass, validClass) {
            $(element).addClass("is-invalid");
        },
        unhighlight: function(element, errorClass, validClass) {
            $(element).removeClass("is-invalid");
        }
    });

    jQuery.validator.addMethod("patientName", function(patientName, element) {
        var valid = false;
        $.ajax({
            url: "/appointment/get-users",
            method: "POST",
            data: {
                query: patientName
            },
            async: false,
            dataType: "json",
            success: function(respond) {
                var patientId = $('#patient_id').val();
                var referralId = $('#referral_id').val();
                $.each(respond.data, function(index, value){
                    if(value.name == patientName && value.user_id == patientId && value.referral_id == referralId){
                        valid = true;
                    }
                });
            }
        });
        return this.optional(element) || valid;
    }, "Please select valid patient name");

    $("#appointmentForm").submit(function(e) {
        e.preventDefault();

        var id = $("#appointment_id").val();
        var formData = new FormData(this);

        if ($("#appointmentForm").valid()) {
            $("#btn-submit-appointment").prop("disabled", true);
            $.ajax({
                type: "POST",
                url: "/appointment",
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                success: data => {
                    $("#btn-submit-appointment").prop("disabled", false);
                    $.notify(
                        {
                            // options
                            message: data.message
                        },
                        {
                            // settings
                            type: data.response ? "success" : "danger",
                            placement: {
                                from: "bottom",
                                align: "right"
                            }
                        }
                    );
                    if (id == "") {
                        this.reset();
                    } else {
                        setTimeout(function() {
                            window.location.href = "/appointment";
                        }, 1000);
                    }
                },
                error: function(data) {
                    $("#btn-submit-appointment").prop("disabled", false);
                    console.log(data);
                }
            });
        }
    });

    $("#btn-modal-appointment-cancel").click(function() {
        var id = $(this).attr("data-id");
        var action = $(this).attr("data-action");

        $("#confirmModal").modal("hide");

        $("#btn-modal-appointment-cancel").prop("disabled", true);
        if (action == "cancel") {
            $.ajax({
                type: "DELETE",
                url: "/appointment/" + id,
                cache: false,
                contentType: false,
                processData: false,
                success: data => {
                    $("#btn-modal-appointment-cancel").prop("disabled", false);
                    $.notify(
                        {
                            // options
                            message: data.message
                        },
                        {
                            // settings
                            type: data.response ? "success" : "danger",
                            placement: {
                                from: "bottom",
                                align: "right"
                            }
                        }
                    );
                    setTimeout(function() {
                        window.location.href = "/appointment";
                    }, 1000);
                },
                error: function(data) {
                    $("#btn-modal-appointment-cancel").prop("disabled", false);
                    console.log(data);
                }
            });
        }
    });

});
